<template>
  <http-error-template
    :application-title="$t('t.PageNotFound')"
    :detail-message="$t('t.PageNotFoundDetail')"
    :error-message="$t('t.PageNotFound')"
    error-code="404"
    :image="image"
  />
</template>

<script>
export default {
  components: {
    HttpErrorTemplate: () => import('./http-error-template.vue')
  },
  computed: {
    image () {
      return require(`@/assets/${this.$vuetify.theme.isDark ? 'light' : 'dark'}-computer.svg`)
    }
  }
}
</script>
